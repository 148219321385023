import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Input,
  Radio,
  Dropdown,
  Checkbox,
  TextArea
} from 'semantic-ui-react'
import {
  TimeFreeTextBox24,
  DateTextBox
} from 'react-lib/apps/common'

const ANES_ANESRecord_01 = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardAnesRecord01"
        style={{ width: "100%", padding:"10px"}}>
        <div
          id="ANES_ANESRecord_01-div-3">
          
          <div
            id="ANES_ANESRecord_01-div-4"
            style={{ gridRow: "1/2", gridColumn: "1/3",marginLeft:"10px",marginTop:"10px", display: "flex", justifyContent: "space-between", marginBottom: "10px", alingItem: "center" }}>
            
            <div
              id="ANES_ANESRecord_01-div-5"
              style={{ display: "flex" ,fontWeight:"Bold",fontSize:"18px",marginBottom:"10px"}}>
              Anesthetic Assessment Record
            </div>
            <div
              id="ANES_ANESRecord_01-div-182"
              style={{display: "flex" }}>
              
              <div
                id="ANES_ANESRecord_01-div-183">
                {props.buttonLog}
              </div>
              <div
                id="ANES_ANESRecord_01-div-184"
                style={{display: "none"}}>
                {props.buttonPrint}
              </div>
              <div
                id="ANES_ANESRecord_01-div-185"
                style={{ display: props.AnesRecordStatus === "CONFIRMED" ?  "none" : "",}}>
                {props.buttonSave}
              </div>
              <div
                id="ANES_ANESRecord_01-div-186"
                style={{ display: props.AnesRecordStatus === "CONFIRMED" ?  "none" : "",}}>
                {props.buttonConfirm}
              </div>
            </div>
          </div>
        </div>
        <div
          id="ANES_ANESRecord_01-div-1"
          style={{backgroundColor: "#EDF6F9",width: "100%",height:"50%",padding:"10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2", ...(props.isPHV && { pointerEvents: "none" })}}>
          
          <div
            id="ANES_ANESRecord_01-div-6"
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(1, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
            <div
              id="ANES_ANESRecord_01-div-178"
              style={{ gridRow: "6/7", gridColumn: "28/40", display: "none" }}>
              
              <div
                id="ANES_ANESRecord_01-div-179">
                {props.buttonLog}
              </div>
              <div
                id="ANES_ANESRecord_01-div-180">
                {props.buttonPrint}
              </div>
              <div
                id="ANES_ANESRecord_01-div-181">
                {props.buttonSave}
              </div>
            </div>
            <div
              id="ANES_ANESRecord_01-div-189"
              style={{ gridRow: "2/3", gridColumn: "28/31",paddingTop:"5px"}}>
              
              <div
                id="ANES_ANESRecord_01-div-192"
                style={{ width:"100%", height:"100%" , display: "flex" , alignItems:"center"}}>
                
              </div>
            </div>
            <div
              id="ANES_ANESRecord_01-div-190"
              style={{ gridRow: "2/3", gridColumn: "10/13",paddingTop:"5px"}}>
              
              <div
                id="ANES_ANESRecord_01-div-191"
                style={{ width:"100%", height:"100%" , display: "flex" , alignItems:"center"}}>
                
              </div>
            </div>
          </div>
          <Form
            id="ANES_ANESRecord_01-Form-196">
            <FormGroup
              id="ANES_ANESRecord_01-FormGroup-197"
              inline={true}>
              <FormField
                id="ANES_ANESRecord_01-FormField-201"
                inline={true}
                width={5}>
                <label
                  id="ANES_ANESRecord_01-label-204"
                  style={{minWidth: "105px",maxWidth: "105px"}}>
                  Operative Date
                </label>
                <div
                  id="ANES_ANESRecord_01-div-253"
                  style={{width: "100%"}}>
                  
                  <DateTextBox
                    disabled={true}
                    id="ANES_ANESRecord_01-DateTextBox-254"
                    onChange={props.onChangeDateForm}
                    value={props.AnesRecordSequence?.DateRamrk}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField
                id="ANES_ANESRecord_01-FormField-202"
                inline={true}
                width={5}>
                <label
                  id="ANES_ANESRecord_01-label-205"
                  style={{minWidth: "120px",maxWidth: "120px"}}>
                  OR Location
                </label>
                <Input
                  disabled={true}
                  id="ANES_ANESRecord_01-Input-208"
                  name="ORRmark"
                  onChange={props.onChangeData}
                  value={props.AnesRecordSequence?.ORRmark}>
                </Input>
              </FormField>
              <FormField
                id="ANES_ANESRecord_01-FormField-203"
                inline={true}
                width={6}>
                <label
                  id="ANES_ANESRecord_01-label-206"
                  style={{minWidth: "120px",maxWidth: "120px"}}>
                  Patient location
                </label>
                <Radio
                  checked={props.AnesRecordSequence?.PatientLocation === "OPD"}
                  id="ANES_ANESRecord_01-Radio-209"
                  label="OPD"
                  name="PatientLocation"
                  onChange={props.onChangeData}
                  style={{minWidth: "max-content"}}
                  value="OPD">
                </Radio>
                <Radio
                  checked={props.AnesRecordSequence?.PatientLocation === "Ward"}
                  id="ANES_ANESRecord_01-Radio-210"
                  label="Ward"
                  name="PatientLocation"
                  onChange={props.onChangeData}
                  style={{marginLeft: "1rem",minWidth: "max-content"}}
                  value="Ward">
                </Radio>
                <Dropdown
                  clearable={true}
                  disabled={props.AnesRecordSequence?.PatientLocation !== "Ward"}
                  fluid={true}
                  id="ANES_ANESRecord_01-Dropdown-211"
                  name="IsWardRemark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.divisionWard}
                  search={true}
                  selection={true}
                  style={{marginLeft: "1rem", width: "100%"}}
                  value={props.AnesRecordSequence?.PatientLocation === "Ward" ? props.AnesRecordSequence?.IsWardRemark : ""}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              id="ANES_ANESRecord_01-FormGroup-198"
              inline={true}
              style={{ display: "none" }}>
              <FormField
                id="ANES_ANESRecord_01-FormField-212"
                inline={true}
                width={5}>
                <label
                  id="ANES_ANESRecord_01-label-213"
                  style={{minWidth: "105px",maxWidth: "105px"}}>
                  Time : Start Anes.
                </label>
                <TimeFreeTextBox24
                  className="time-custom-react-picker"
                  id="ANES_ANESRecord_01-TimeFreeTextBox24-214"
                  onChange={props.onChangeStartAnesTime}
                  value={props.AnesRecordSequence?.StartAnesTime}>
                </TimeFreeTextBox24>
                <Checkbox
                  checked={props.AnesRecordSequence?.startAfterClinic}
                  id="ANES_ANESRecord_01-Checkbox-215"
                  label="นอกเวลาราชการ"
                  name="startAfterClinic"
                  onChange={props.onChangeData}
                  style={{marginLeft: "0.5rem", minWidth: "6rem"}}>
                </Checkbox>
              </FormField>
              <FormField
                id="ANES_ANESRecord_01-FormField-222"
                inline={true}>
                <label
                  id="ANES_ANESRecord_01-label-228"
                  style={{minWidth: "max-content"}}>
                  Start Op.
                </label>
              </FormField>
              <FormField
                id="ANES_ANESRecord_01-FormField-223"
                inline={true}>
                <TimeFreeTextBox24
                  className="time-custom-react-picker"
                  id="ANES_ANESRecord_01-TimeFreeTextBox24-229"
                  onChange={props.onChangeStartOpTime}
                  value={props.AnesRecordSequence?.StartOpTime}>
                </TimeFreeTextBox24>
              </FormField>
              <FormField
                id="ANES_ANESRecord_01-FormField-224"
                inline={true}>
                <label
                  id="ANES_ANESRecord_01-label-231"
                  style={{minWidth: "max-content"}}>
                  End Anes.
                </label>
              </FormField>
              <FormField
                id="ANES_ANESRecord_01-FormField-225"
                inline={true}>
                <TimeFreeTextBox24
                  className="time-custom-react-picker"
                  id="ANES_ANESRecord_01-TimeFreeTextBox24-230"
                  onChange={props.onChangeEndAnesTime}
                  value={props.AnesRecordSequence?.EndAnesTime}>
                </TimeFreeTextBox24>
              </FormField>
              <FormField
                id="ANES_ANESRecord_01-FormField-226"
                inline={true}>
                <Checkbox
                  checked={props.AnesRecordSequence?.endAfterClinic}
                  id="ANES_ANESRecord_01-Checkbox-255"
                  label="นอกเวลาราชการ"
                  name="endAfterClinic"
                  onChange={props.onChangeData}>
                </Checkbox>
              </FormField>
              <FormField
                id="ANES_ANESRecord_01-FormField-227"
                inline={true}
                style={{padding: 0}}
                width={4}>
                <label
                  id="ANES_ANESRecord_01-label-233"
                  style={{maxWidth: "max-content", width: "100%"}}>
                  PACU duration
                </label>
                <Input
                  fluid={true}
                  id="ANES_ANESRecord_01-Input-235"
                  name="PACUDurartion"
                  onChange={props.onChangeData}
                  style={{width: "100%"}}
                  value={props.AnesRecordSequence?.PACUDurartion}>
                </Input>
                <label
                  id="ANES_ANESRecord_01-label-234"
                  style={{marginLeft: "1rem"}}>
                  min
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              id="ANES_ANESRecord_01-FormGroup-199"
              inline={true}
              style={{ display: "none" }}>
              <FormField
                id="ANES_ANESRecord_01-FormField-216"
                inline={true}
                width={5}>
                <label
                  id="ANES_ANESRecord_01-label-236"
                  style={{minWidth: "105px",maxWidth: "105px"}}>
                  Anesthesia team
                </label>
                <Input
                  fluid={true}
                  id="ANES_ANESRecord_01-Input-239"
                  name="AnesthesiaTeam"
                  onChange={props.onChangeData}
                  style={{width: "100%"}}
                  value={props.AnesRecordSequence?.AnesthesiaTeam}>
                </Input>
              </FormField>
              <FormField
                id="ANES_ANESRecord_01-FormField-220"
                inline={true}
                width={5}>
                <label
                  id="ANES_ANESRecord_01-label-237"
                  style={{minWidth: "120px",maxWidth: "120px"}}>
                  Pre-operative Dx
                </label>
                <Input
                  fluid={true}
                  id="ANES_ANESRecord_01-Input-240"
                  name="PreoperativeDiagnosis"
                  onChange={props.onChangeData}
                  readOnly={true}
                  style={{width: "100%"}}
                  value={props.AnesRecordSequence?.PreoperativeDiagnosis}>
                </Input>
              </FormField>
              <FormField
                id="ANES_ANESRecord_01-FormField-221"
                inline={true}
                width={6}>
                <label
                  id="ANES_ANESRecord_01-label-238"
                  style={{minWidth: "125px",maxWidth: "125px"}}>
                  Post-operative Dx
                </label>
                <Input
                  fluid={true}
                  id="ANES_ANESRecord_01-Input-241"
                  name="PostoperativeDiagnosis"
                  onChange={props.onChangeData}
                  readOnly={true}
                  style={{width: "100%"}}
                  value={props.AnesRecordSequence?.PostoperativeDiagnosis}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              id="ANES_ANESRecord_01-FormGroup-200"
              inline={true}
              style={{ display: "none" }}>
              <FormField
                id="ANES_ANESRecord_01-FormField-242"
                inline={true}
                width={5}>
                <label
                  id="ANES_ANESRecord_01-label-246"
                  style={{minWidth: "105px",maxWidth: "105px"}}>
                  Surgical team
                </label>
                <Input
                  fluid={true}
                  id="ANES_ANESRecord_01-Input-249"
                  name="SurgicalTeam"
                  onChange={props.onChangeData}
                  style={{width: "100%"}}
                  value={props.AnesRecordSequence?.SurgicalTeam}>
                </Input>
              </FormField>
              <FormField
                id="ANES_ANESRecord_01-FormField-244"
                inline={true}
                width={5}>
                <label
                  id="ANES_ANESRecord_01-label-247"
                  style={{minWidth: "120px",maxWidth: "120px"}}>
                  Proposed operation
                </label>
                <Input
                  fluid={true}
                  id="ANES_ANESRecord_01-Input-250"
                  name="ProposedOperation"
                  onChange={props.onChangeData}
                  readOnly={true}
                  style={{width: "100%"}}
                  value={props.AnesRecordSequence?.ProposedOperation}>
                </Input>
              </FormField>
              <FormField
                id="ANES_ANESRecord_01-FormField-245"
                inline={true}
                width={6}>
                <label
                  id="ANES_ANESRecord_01-label-248"
                  style={{minWidth: "125px",maxWidth: "125px"}}>
                  Performed operation
                </label>
                <Input
                  fluid={true}
                  id="ANES_ANESRecord_01-Input-251"
                  name="PerformedOperation"
                  onChange={props.onChangeData}
                  readOnly={true}
                  style={{width: "100%"}}
                  value={props.AnesRecordSequence?.PerformedOperation}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              id="ANES_ANESRecord_01-FormGroup-293"
              inline={true}>
              <FormField
                id="ANES_ANESRecord_01-FormField-294"
                inline={true}
                style={{ alignItems: "baseline"  }}
                width={5}>
                <label
                  id="ANES_ANESRecord_01-label-318"
                  style={{minWidth: "105px",maxWidth: "105px"}}>
                  Surgical team
                </label>
                <TextArea
                  disabled={true}
                  id="ANES_ANESRecord_01-TextArea-342"
                  name="SurgicalTeam"
                  onChange={props.onChangeData}
                  style={{width: "100%", resize: "none"}}
                  value={props.AnesRecordSequence?.SurgicalTeam}>
                </TextArea>
              </FormField>
              <FormField
                id="ANES_ANESRecord_01-FormField-295"
                inline={true}
                width={5}>
                <div
                  id="ANES_ANESRecord_01-div-322"
                  style={{ width: "100%"}}>
                  
                  <FormField
                    id="ANES_ANESRecord_01-FormField-324"
                    inline={true}
                    style={{ marginBottom: "10px"}}
                    width={16}>
                    <label
                      id="ANES_ANESRecord_01-label-328"
                      style={{minWidth: "130px",maxWidth: "130px"}}>
                      Pre-operative Dx
                    </label>
                    <Input
                      disabled={true}
                      fluid={true}
                      id="ANES_ANESRecord_01-Input-333"
                      name="PreoperativeDiagnosis"
                      onChange={props.onChangeData}
                      readOnly={true}
                      style={{width: "100%"}}
                      value={props.AnesRecordSequence?.PreoperativeDiagnosis}>
                    </Input>
                  </FormField>
                  <FormField
                    id="ANES_ANESRecord_01-FormField-336"
                    inline={true}
                    width={16}>
                    <label
                      id="ANES_ANESRecord_01-label-338"
                      style={{minWidth: "130px",maxWidth: "130px"}}>
                      Proposed operation
                    </label>
                    <Input
                      disabled={true}
                      fluid={true}
                      id="ANES_ANESRecord_01-Input-340"
                      name="ProposedOperation"
                      onChange={props.onChangeData}
                      readOnly={true}
                      style={{width: "100%"}}
                      value={props.AnesRecordSequence?.ProposedOperation}>
                    </Input>
                  </FormField>
                </div>
              </FormField>
              <FormField
                id="ANES_ANESRecord_01-FormField-296"
                inline={true}
                width={6}>
                <div
                  id="ANES_ANESRecord_01-div-320"
                  style={{ width: "100%"}}>
                  
                  <FormField
                    id="ANES_ANESRecord_01-FormField-326"
                    inline={true}
                    style={{ marginBottom: "10px"}}
                    width={16}>
                    <label
                      id="ANES_ANESRecord_01-label-330"
                      style={{minWidth: "130px",maxWidth: "130px"}}>
                      Post-operative Dx
                    </label>
                    <Input
                      disabled={true}
                      fluid={true}
                      id="ANES_ANESRecord_01-Input-331"
                      name="PostoperativeDiagnosis"
                      onChange={props.onChangeData}
                      readOnly={true}
                      style={{width: "100%"}}
                      value={props.AnesRecordSequence?.PostoperativeDiagnosis}>
                    </Input>
                  </FormField>
                  <FormField
                    id="ANES_ANESRecord_01-FormField-337"
                    inline={true}
                    width={16}>
                    <label
                      id="ANES_ANESRecord_01-label-339"
                      style={{minWidth: "130px",maxWidth: "130px"}}>
                      Performed operation
                    </label>
                    <Input
                      disabled={true}
                      fluid={true}
                      id="ANES_ANESRecord_01-Input-341"
                      name="PerformedOperation"
                      onChange={props.onChangeData}
                      readOnly={true}
                      style={{width: "100%"}}
                      value={props.AnesRecordSequence?.PerformedOperation}>
                    </Input>
                  </FormField>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="ANES_ANESRecord_01-FormGroup-256"
              inline={true}>
              <FormField
                id="ANES_ANESRecord_01-FormField-258"
                inline={true}
                width={5}>
                <label
                  id="ANES_ANESRecord_01-label-265"
                  style={{minWidth: "105px", maxWidth: "105px"}}>
                  Start Anes.
                </label>
                <div
                  id="ANES_ANESRecord_01-div-269"
                  style={{ width: "50%" }}>
                  
                  <DateTextBox
                    id="ANES_ANESRecord_01-DateTextBox-271"
                    onChange={props.onChangeStartDate}
                    value={props.AnesRecordSequence?.StartAnesDate}>
                  </DateTextBox>
                </div>
                <div
                  id="ANES_ANESRecord_01-div-281"
                  style={{width: "fit-content"}}>
                  
                  <TimeFreeTextBox24
                    className="time-custom-react-picker"
                    id="ANES_ANESRecord_01-TimeFreeTextBox24-282"
                    onChange={props.onChangeStartAnesTime}
                    value={props.AnesRecordSequence?.StartAnesTime}>
                  </TimeFreeTextBox24>
                </div>
              </FormField>
              <FormField
                id="ANES_ANESRecord_01-FormField-260"
                inline={true}
                width={5}>
                <label
                  id="ANES_ANESRecord_01-label-273"
                  style={{minWidth: "105px", maxWidth: "105px"}}>
                  End
                </label>
                <div
                  id="ANES_ANESRecord_01-div-270"
                  style={{ width: "50%" }}>
                  
                  <DateTextBox
                    id="ANES_ANESRecord_01-DateTextBox-272"
                    onChange={props.onChangeEndDate}
                    value={props.AnesRecordSequence?.EndAnesDate}>
                  </DateTextBox>
                </div>
                <div
                  id="ANES_ANESRecord_01-div-283"
                  style={{width: "fit-content"}}>
                  
                  <TimeFreeTextBox24
                    className="time-custom-react-picker"
                    id="ANES_ANESRecord_01-TimeFreeTextBox24-284"
                    onChange={props.onChangeEndAnesTime}
                    value={props.AnesRecordSequence?.EndAnesTime}>
                  </TimeFreeTextBox24>
                </div>
              </FormField>
              <FormField
                id="ANES_ANESRecord_01-FormField-285">
                <Checkbox
                  checked={props.AnesRecordSequence?.startAfterClinic}
                  id="ANES_ANESRecord_01-Checkbox-286"
                  label="นอกเวลา"
                  name="startAfterClinic"
                  onChange={props.onChangeData}
                  style={{marginLeft: "0.5rem", minWidth: "6rem"}}>
                </Checkbox>
              </FormField>
              <FormField
                id="ANES_ANESRecord_01-FormField-264"
                inline={true}
                style={{padding: 0}}
                width={4}>
                <label
                  id="ANES_ANESRecord_01-label-275"
                  style={{width: "fit-content"}}>
                  Total Anesthetic time
                </label>
                <Input
                  disabled={true}
                  id="ANES_ANESRecord_01-Input-279"
                  style={{ width: "20%" }}
                  value={props.AnesRecordSequence?.TotalAnseTimeHour || 0}>
                </Input>
                <label
                  id="ANES_ANESRecord_01-label-277"
                  style={{margin: "0px 1rem"}}>
                  hr(s)
                </label>
                <Input
                  disabled={true}
                  id="ANES_ANESRecord_01-Input-280"
                  style={{ width: "20%" }}
                  value={props.AnesRecordSequence?.TotalAnseTimeMinute || 0}>
                </Input>
                <label
                  id="ANES_ANESRecord_01-label-278"
                  style={{marginLeft: "1rem"}}>
                  min
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              id="ANES_ANESRecord_01-FormGroup-257"
              inline={true}
              style={{ alignItems: "flex-start" }}>
              <FormField
                id="ANES_ANESRecord_01-FormField-287"
                inline={true}
                style={{ alignItems: "center" }}
                width={5}>
                <label
                  id="ANES_ANESRecord_01-label-289"
                  style={{minWidth: "95px", maxWidth: "95px"}}>
                  Anesthesiologist
                </label>
                <Dropdown
                  id="ANES_ANESRecord_01-Dropdown-292"
                  name="Anesthesiologist"
                  onChange={props.onChangeData}
                  options={props.masterOptions.doctor || []}
                  selection={true}
                  style={{width: "100%", display: "none"}}
                  value={props.AnesRecordSequence?.Anesthesiologist || ""}>
                </Dropdown>
                <div
                  id="ANES_ANESRecord_01-div-344"
                  style={{ width: "100%" }}>
                  {props.SurgeonElement}
                </div>
              </FormField>
              <FormField
                id="ANES_ANESRecord_01-FormField-288"
                inline={true}
                style={{ alignItems: "flex-start" }}
                width={11}>
                <label
                  id="ANES_ANESRecord_01-label-290"
                  style={{minWidth: "115px", maxWidth: "115px", marginTop: "7px"}}>
                  Anesthesia nurse
                </label>
                <div
                  id="ANES_ANESRecord_01-div-343"
                  style={{ width: "100%" }}>
                  {props.AnesthetistElement}
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
}


export default ANES_ANESRecord_01

export const screenPropsDefault = {}

/* Date Time : Mon Feb 24 2025 17:54:01 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAnesRecord01"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-1"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",height:\"50%\",padding:\"10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\", ...(props.isPHV && { pointerEvents: \"none\" })}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-3"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-4"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/3\",marginLeft:\"10px\",marginTop:\"10px\", display: \"flex\", justifyContent: \"space-between\", marginBottom: \"10px\", alingItem: \"center\" }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthetic Assessment Record"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-5"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,fontWeight:\"Bold\",fontSize:\"18px\",marginBottom:\"10px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-6"
        },
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-178"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"28/40\", display: \"none\" }"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "div",
      "parent": 178,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonLog"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-179"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "div",
      "parent": 178,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-180"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "div",
      "parent": 178,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-181"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-182"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\" }"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 182,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonLog"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-183"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "div",
      "parent": 182,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-184"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "div",
      "parent": 182,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-185"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.AnesRecordStatus === \"CONFIRMED\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 186,
      "name": "div",
      "parent": 182,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-186"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.AnesRecordStatus === \"CONFIRMED\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-189"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"28/31\",paddingTop:\"5px\"}"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-190"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"10/13\",paddingTop:\"5px\"}"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "div",
      "parent": 190,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-191"
        },
        "style": {
          "type": "code",
          "value": "{ width:\"100%\", height:\"100%\" , display: \"flex\" , alignItems:\"center\"}"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "div",
      "parent": 189,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-192"
        },
        "style": {
          "type": "code",
          "value": "{ width:\"100%\", height:\"100%\" , display: \"flex\" , alignItems:\"center\"}"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "Form",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Form-196"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "FormGroup",
      "parent": 196,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormGroup-197"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "FormGroup",
      "parent": 196,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormGroup-198"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "FormGroup",
      "parent": 196,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormGroup-199"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "FormGroup",
      "parent": 196,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormGroup-200"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 201,
      "name": "FormField",
      "parent": 197,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-201"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 202,
      "name": "FormField",
      "parent": 197,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-202"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 203,
      "name": "FormField",
      "parent": 197,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-203"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": null,
      "id": 204,
      "name": "label",
      "parent": 201,
      "props": {
        "children": {
          "type": "value",
          "value": "Operative Date"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-204"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\",maxWidth: \"105px\"}"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": null,
      "id": 205,
      "name": "label",
      "parent": 202,
      "props": {
        "children": {
          "type": "value",
          "value": "OR Location"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-205"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\",maxWidth: \"120px\"}"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": null,
      "id": 206,
      "name": "label",
      "parent": 203,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient location"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-206"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\",maxWidth: \"120px\"}"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 208,
      "name": "Input",
      "parent": 202,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Input-208"
        },
        "name": {
          "type": "value",
          "value": "ORRmark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.ORRmark"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 209,
      "name": "Radio",
      "parent": 203,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PatientLocation === \"OPD\""
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Radio-209"
        },
        "label": {
          "type": "value",
          "value": "OPD"
        },
        "name": {
          "type": "value",
          "value": "PatientLocation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "value": {
          "type": "value",
          "value": "OPD"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 210,
      "name": "Radio",
      "parent": 203,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PatientLocation === \"Ward\""
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Radio-210"
        },
        "label": {
          "type": "value",
          "value": "Ward"
        },
        "name": {
          "type": "value",
          "value": "PatientLocation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\",minWidth: \"max-content\"}"
        },
        "value": {
          "type": "value",
          "value": "Ward"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 211,
      "name": "Dropdown",
      "parent": 203,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PatientLocation !== \"Ward\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Dropdown-211"
        },
        "name": {
          "type": "value",
          "value": "IsWardRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.divisionWard"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PatientLocation === \"Ward\" ? props.AnesRecordSequence?.IsWardRemark : \"\""
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 212,
      "name": "FormField",
      "parent": 198,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-212"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": null,
      "id": 213,
      "name": "label",
      "parent": 212,
      "props": {
        "children": {
          "type": "value",
          "value": "Time : Start Anes."
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-213"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\",maxWidth: \"105px\"}"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 214,
      "name": "TimeFreeTextBox24",
      "parent": 212,
      "props": {
        "className": {
          "type": "value",
          "value": "time-custom-react-picker"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-TimeFreeTextBox24-214"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartAnesTime"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.StartAnesTime"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 215,
      "name": "Checkbox",
      "parent": 212,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.startAfterClinic"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Checkbox-215"
        },
        "label": {
          "type": "value",
          "value": "นอกเวลาราชการ"
        },
        "name": {
          "type": "value",
          "value": "startAfterClinic"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.5rem\", minWidth: \"6rem\"}"
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 216,
      "name": "FormField",
      "parent": 199,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-216"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 220,
      "name": "FormField",
      "parent": 199,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-220"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 220,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 221,
      "name": "FormField",
      "parent": 199,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-221"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 222,
      "name": "FormField",
      "parent": 198,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-222"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 223,
      "name": "FormField",
      "parent": 198,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-223"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 224,
      "name": "FormField",
      "parent": 198,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-224"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 225,
      "name": "FormField",
      "parent": 198,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-225"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 226,
      "name": "FormField",
      "parent": 198,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-226"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 227,
      "name": "FormField",
      "parent": 198,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-227"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": null,
      "id": 228,
      "name": "label",
      "parent": 222,
      "props": {
        "children": {
          "type": "value",
          "value": "Start Op."
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-228"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 229,
      "name": "TimeFreeTextBox24",
      "parent": 223,
      "props": {
        "className": {
          "type": "value",
          "value": "time-custom-react-picker"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-TimeFreeTextBox24-229"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartOpTime"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.StartOpTime"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 230,
      "name": "TimeFreeTextBox24",
      "parent": 225,
      "props": {
        "className": {
          "type": "value",
          "value": "time-custom-react-picker"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-TimeFreeTextBox24-230"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndAnesTime"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.EndAnesTime"
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": null,
      "id": 231,
      "name": "label",
      "parent": 224,
      "props": {
        "children": {
          "type": "value",
          "value": "End Anes."
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-231"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": null,
      "id": 233,
      "name": "label",
      "parent": 227,
      "props": {
        "children": {
          "type": "value",
          "value": "PACU duration"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-233"
        },
        "style": {
          "type": "code",
          "value": "{maxWidth: \"max-content\", width: \"100%\"}"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": null,
      "id": 234,
      "name": "label",
      "parent": 227,
      "props": {
        "children": {
          "type": "value",
          "value": "min"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-234"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 235,
      "name": "Input",
      "parent": 227,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Input-235"
        },
        "name": {
          "type": "value",
          "value": "PACUDurartion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PACUDurartion"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": null,
      "id": 236,
      "name": "label",
      "parent": 216,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesia team"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-236"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\",maxWidth: \"105px\"}"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": null,
      "id": 237,
      "name": "label",
      "parent": 220,
      "props": {
        "children": {
          "type": "value",
          "value": "Pre-operative Dx"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-237"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\",maxWidth: \"120px\"}"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": null,
      "id": 238,
      "name": "label",
      "parent": 221,
      "props": {
        "children": {
          "type": "value",
          "value": "Post-operative Dx"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-238"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"125px\",maxWidth: \"125px\"}"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 239,
      "name": "Input",
      "parent": 216,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Input-239"
        },
        "name": {
          "type": "value",
          "value": "AnesthesiaTeam"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AnesthesiaTeam"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 240,
      "name": "Input",
      "parent": 220,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Input-240"
        },
        "name": {
          "type": "value",
          "value": "PreoperativeDiagnosis"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "readonly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PreoperativeDiagnosis"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 241,
      "name": "Input",
      "parent": 221,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Input-241"
        },
        "name": {
          "type": "value",
          "value": "PostoperativeDiagnosis"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PostoperativeDiagnosis"
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 242,
      "name": "FormField",
      "parent": 200,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-242"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 244,
      "name": "FormField",
      "parent": 200,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-244"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 245,
      "name": "FormField",
      "parent": 200,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-245"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": null,
      "id": 246,
      "name": "label",
      "parent": 242,
      "props": {
        "children": {
          "type": "value",
          "value": "Surgical team"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-246"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\",maxWidth: \"105px\"}"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": null,
      "id": 247,
      "name": "label",
      "parent": 244,
      "props": {
        "children": {
          "type": "value",
          "value": "Proposed operation"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-247"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\",maxWidth: \"120px\"}"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": null,
      "id": 248,
      "name": "label",
      "parent": 245,
      "props": {
        "children": {
          "type": "value",
          "value": "Performed operation"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-248"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"125px\",maxWidth: \"125px\"}"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 249,
      "name": "Input",
      "parent": 242,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Input-249"
        },
        "name": {
          "type": "value",
          "value": "SurgicalTeam"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.SurgicalTeam"
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 250,
      "name": "Input",
      "parent": 244,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Input-250"
        },
        "name": {
          "type": "value",
          "value": "ProposedOperation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.ProposedOperation"
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 251,
      "name": "Input",
      "parent": 245,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Input-251"
        },
        "name": {
          "type": "value",
          "value": "PerformedOperation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PerformedOperation"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": null,
      "id": 253,
      "name": "div",
      "parent": 201,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-253"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 254,
      "name": "DateTextBox",
      "parent": 253,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-DateTextBox-254"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDateForm"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.DateRamrk"
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 255,
      "name": "Checkbox",
      "parent": 226,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.endAfterClinic"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Checkbox-255"
        },
        "label": {
          "type": "value",
          "value": "นอกเวลาราชการ"
        },
        "name": {
          "type": "value",
          "value": "endAfterClinic"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 256,
      "name": "FormGroup",
      "parent": 196,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormGroup-256"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 257,
      "name": "FormGroup",
      "parent": 196,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormGroup-257"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ alignItems: \"flex-start\" }"
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 258,
      "name": "FormField",
      "parent": 256,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-258"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 258,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 260,
      "name": "FormField",
      "parent": 256,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-260"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 260,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 264,
      "name": "FormField",
      "parent": 256,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-264"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 285,
      "void": false
    },
    {
      "from": null,
      "id": 265,
      "name": "label",
      "parent": 258,
      "props": {
        "children": {
          "type": "value",
          "value": "Start Anes."
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-265"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\", maxWidth: \"105px\"}"
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": null,
      "id": 269,
      "name": "div",
      "parent": 258,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-269"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": null,
      "id": 270,
      "name": "div",
      "parent": 260,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-270"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 271,
      "name": "DateTextBox",
      "parent": 269,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-DateTextBox-271"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartDate"
        },
        "placeholder": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.StartAnesDate"
        }
      },
      "seq": 271,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 272,
      "name": "DateTextBox",
      "parent": 270,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-DateTextBox-272"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndDate"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.EndAnesDate"
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": null,
      "id": 273,
      "name": "label",
      "parent": 260,
      "props": {
        "children": {
          "type": "value",
          "value": "End"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-273"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\", maxWidth: \"105px\"}"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": null,
      "id": 275,
      "name": "label",
      "parent": 264,
      "props": {
        "children": {
          "type": "value",
          "value": "Total Anesthetic time"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-275"
        },
        "style": {
          "type": "code",
          "value": "{width: \"fit-content\"}"
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": null,
      "id": 277,
      "name": "label",
      "parent": 264,
      "props": {
        "children": {
          "type": "value",
          "value": "hr(s)"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-277"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 1rem\"}"
        }
      },
      "seq": 278,
      "void": false
    },
    {
      "from": null,
      "id": 278,
      "name": "label",
      "parent": 264,
      "props": {
        "children": {
          "type": "value",
          "value": "min"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-278"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 279,
      "name": "Input",
      "parent": 264,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Input-279"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.TotalAnseTimeHour || 0"
        }
      },
      "seq": 277,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 280,
      "name": "Input",
      "parent": 264,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Input-280"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.TotalAnseTimeMinute || 0"
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": null,
      "id": 281,
      "name": "div",
      "parent": 258,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-281"
        },
        "style": {
          "type": "code",
          "value": "{width: \"fit-content\"}"
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 282,
      "name": "TimeFreeTextBox24",
      "parent": 281,
      "props": {
        "className": {
          "type": "value",
          "value": "time-custom-react-picker"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-TimeFreeTextBox24-282"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartAnesTime"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.StartAnesTime"
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": null,
      "id": 283,
      "name": "div",
      "parent": 260,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-283"
        },
        "style": {
          "type": "code",
          "value": "{width: \"fit-content\"}"
        }
      },
      "seq": 283,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 284,
      "name": "TimeFreeTextBox24",
      "parent": 283,
      "props": {
        "className": {
          "type": "value",
          "value": "time-custom-react-picker"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-TimeFreeTextBox24-284"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndAnesTime"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.EndAnesTime"
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 285,
      "name": "FormField",
      "parent": 256,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-285"
        }
      },
      "seq": 264,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 286,
      "name": "Checkbox",
      "parent": 285,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.startAfterClinic"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Checkbox-286"
        },
        "label": {
          "type": "value",
          "value": "นอกเวลา"
        },
        "name": {
          "type": "value",
          "value": "startAfterClinic"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.5rem\", minWidth: \"6rem\"}"
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 287,
      "name": "FormField",
      "parent": 257,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-287"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ alignItems: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 287,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 288,
      "name": "FormField",
      "parent": 257,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-288"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ alignItems: \"flex-start\" }"
        },
        "width": {
          "type": "code",
          "value": "11"
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": null,
      "id": 289,
      "name": "label",
      "parent": 287,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesiologist"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-289"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"95px\", maxWidth: \"95px\"}"
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": null,
      "id": 290,
      "name": "label",
      "parent": 288,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesia nurse"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-290"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"115px\", maxWidth: \"115px\", marginTop: \"7px\"}"
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 292,
      "name": "Dropdown",
      "parent": 287,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Dropdown-292"
        },
        "name": {
          "type": "value",
          "value": "Anesthesiologist"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions.doctor || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", display: \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.Anesthesiologist || \"\""
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 293,
      "name": "FormGroup",
      "parent": 196,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormGroup-293"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 294,
      "name": "FormField",
      "parent": 293,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-294"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ alignItems: \"baseline\"  }"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 294,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 295,
      "name": "FormField",
      "parent": 293,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-295"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 295,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 296,
      "name": "FormField",
      "parent": 293,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-296"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 296,
      "void": false
    },
    {
      "from": null,
      "id": 318,
      "name": "label",
      "parent": 294,
      "props": {
        "children": {
          "type": "value",
          "value": "Surgical team"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-318"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\",maxWidth: \"105px\"}"
        }
      },
      "seq": 297,
      "void": false
    },
    {
      "from": null,
      "id": 320,
      "name": "div",
      "parent": 296,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-320"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\"}"
        }
      },
      "seq": 320,
      "void": false
    },
    {
      "from": null,
      "id": 322,
      "name": "div",
      "parent": 295,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-322"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\"}"
        }
      },
      "seq": 322,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 324,
      "name": "FormField",
      "parent": 322,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-324"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginBottom: \"10px\"}"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 324,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 326,
      "name": "FormField",
      "parent": 320,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-326"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginBottom: \"10px\"}"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 326,
      "void": false
    },
    {
      "from": null,
      "id": 328,
      "name": "label",
      "parent": 324,
      "props": {
        "children": {
          "type": "value",
          "value": "Pre-operative Dx"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-328"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\",maxWidth: \"130px\"}"
        }
      },
      "seq": 328,
      "void": false
    },
    {
      "from": null,
      "id": 330,
      "name": "label",
      "parent": 326,
      "props": {
        "children": {
          "type": "value",
          "value": "Post-operative Dx"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-330"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\",maxWidth: \"130px\"}"
        }
      },
      "seq": 330,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 331,
      "name": "Input",
      "parent": 326,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Input-331"
        },
        "name": {
          "type": "value",
          "value": "PostoperativeDiagnosis"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PostoperativeDiagnosis"
        }
      },
      "seq": 331,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 333,
      "name": "Input",
      "parent": 324,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Input-333"
        },
        "name": {
          "type": "value",
          "value": "PreoperativeDiagnosis"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PreoperativeDiagnosis"
        }
      },
      "seq": 333,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 336,
      "name": "FormField",
      "parent": 322,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-336"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 336,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 337,
      "name": "FormField",
      "parent": 320,
      "props": {
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-FormField-337"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 337,
      "void": false
    },
    {
      "from": null,
      "id": 338,
      "name": "label",
      "parent": 336,
      "props": {
        "children": {
          "type": "value",
          "value": "Proposed operation"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-338"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\",maxWidth: \"130px\"}"
        }
      },
      "seq": 338,
      "void": false
    },
    {
      "from": null,
      "id": 339,
      "name": "label",
      "parent": 337,
      "props": {
        "children": {
          "type": "value",
          "value": "Performed operation"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-label-339"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\",maxWidth: \"130px\"}"
        }
      },
      "seq": 339,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 340,
      "name": "Input",
      "parent": 336,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Input-340"
        },
        "name": {
          "type": "value",
          "value": "ProposedOperation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.ProposedOperation"
        }
      },
      "seq": 340,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 341,
      "name": "Input",
      "parent": 337,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-Input-341"
        },
        "name": {
          "type": "value",
          "value": "PerformedOperation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PerformedOperation"
        }
      },
      "seq": 341,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 342,
      "name": "TextArea",
      "parent": 294,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-TextArea-342"
        },
        "name": {
          "type": "value",
          "value": "SurgicalTeam"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", resize: \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.SurgicalTeam"
        }
      },
      "seq": 342,
      "void": true
    },
    {
      "from": null,
      "id": 343,
      "name": "div",
      "parent": 288,
      "props": {
        "children": {
          "type": "code",
          "value": "props.AnesthetistElement"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-343"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 343,
      "void": false
    },
    {
      "from": null,
      "id": 344,
      "name": "div",
      "parent": 287,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SurgeonElement"
        },
        "id": {
          "type": "value",
          "value": "ANES_ANESRecord_01-div-344"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 344,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_ANESRecord_01",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
